import _ from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Form, Message, WppButton, WppTextareaInput,
} from 'buildingBlocks';
import { RESULTS_LIMIT } from 'constantsBase';
import { ModalSessionFlightsInfoType } from 'containers/StrategyWizard/types';
import { FlightDisplayName, flightKey } from 'containers/StrategyWizard/utils';
import { ATTACH_DSP_OBJECT_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { FLIGHT_ATTACH_STATUS, MAX_FLIGHTS_ALLOWED } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { Eligibility, FlightCandidate } from 'utils/copilotAPI';
import { Flight, TextareaInputChangeEventDetail, WppTextareaInputCustomEvent } from 'utils/types';
import { pluralizer } from 'utils/formattingUtils';
import FlightUploadMessage from './FlightUploadMessage';
import { mergeFlights } from './utils';

const MAX_UPLOAD_AT_ONCE = MAX_FLIGHTS_ALLOWED;

const flightPlur = (numFlights: number, flightDisplayName: FlightDisplayName) => (
  pluralizer(flightDisplayName.single, flightDisplayName.multiple)(numFlights)
);

const isArePlur = (numFlights) => pluralizer('is', 'are')(numFlights);

const alreadyAttachedPlur = (numFlights) => (
  pluralizer('is attached to another strategy', 'are attached to other strategies')(numFlights)
);

const getMsgHeader = (flights, flightDisplayName) => {
  const numFlights = flights.length;
  return {
    notFoundFlights: `${numFlights} ${flightPlur(numFlights, flightDisplayName)} could not be found`,
    bulkUploadedFlights: `${numFlights} new ${flightPlur(numFlights, flightDisplayName)} will be attached`,
    alreadyAttachedFlights: `${flights.length} ${flightPlur(numFlights, flightDisplayName)} ${alreadyAttachedPlur(numFlights)}`,
    attachedToCurrentFlights: `
      ${flights.length} ${flightPlur(numFlights, flightDisplayName)} ${isArePlur(numFlights)} already attached to this strategy`,
  };
};

export type FlightIds = Array<string | number>;

type Props = {
  advertiserId: number
  memberId: number
  externalTypeIds: Array<number>
  flightDisplayName: FlightDisplayName
  modalSessionFlightsInfo: ModalSessionFlightsInfoType
  setModalSessionFlightsInfo: (arg: any) => void
  sessionAttachFlights: Array<Flight>
  setSessionAttachFlights: (arg: Array<Flight>) => void
  strategyTypeId?: number
  strategyId?: number
};

export const BulkUploader = ({
  advertiserId,
  memberId,
  externalTypeIds,
  flightDisplayName,
  modalSessionFlightsInfo,
  sessionAttachFlights,
  setModalSessionFlightsInfo,
  setSessionAttachFlights,
  strategyTypeId,
  strategyId,
}: Props) => {
  const [bulkFlightIds, setBulkFlightIds] = useState<Array<string>>([]);
  const { getValues } = useFormContext();

  const {
    bulkUpload: {
      bulkUploadLoading,
      bulkUploadFailedMsg,
      notFoundFlights,
      attachedToCurrentFlights,
    },
  } = modalSessionFlightsInfo;

  const attachedFlights = getValues('attachedFlights');
  const disabled = _.isEmpty(bulkFlightIds);
  const bulkUploadedFlights = _.map(modalSessionFlightsInfo.bulkUpload.bulkUploadedFlights, 'externalId');
  const maxUploadLimit = MAX_UPLOAD_AT_ONCE - _.size(attachedFlights);

  const onTextChange = (value: string) => {
    const inputIds = _.filter(_.split(value, /\W+/), _.negate(_.isEmpty));
    if (inputIds.length > maxUploadLimit) {
      setBulkFlightIds([]);
      return;
    }
    setBulkFlightIds(inputIds);
  };

  const isAlreadyAttached = (elig, id) => {
    const currentStrategyId = _.get(elig, 'strategy');
    if (!currentStrategyId || currentStrategyId === id) {
      return false;
    }
    return true;
  };

  const bulkUpload = async (flightsInfo, limit = RESULTS_LIMIT) => {
    try {
      const ids = _.chunk(bulkFlightIds, 500);
      const chunkedCandidates = await Promise.all(_.map(ids, (chunk) => {
        const request = {
          advertiserId,
          memberId,
          strategyTypeId,
          strategyId,
          limit,
          externalIds: chunk,
          externalTypeIds,
        };
        return FlightCandidate.get(request);
      }));

      const candidates = _.reduce(chunkedCandidates, (result, value) => {
        result.push(...value.data);
        return result;
      }, []);

      const candidateExtIds = _.map(candidates, 'externalId');
      const currentNotFoundFlights = _.uniq(_.difference(bulkFlightIds, candidateExtIds)) as FlightIds;
      const attachedToCurrent = _.intersection(bulkFlightIds, _.map(attachedFlights, 'externalId')) as FlightIds;

      if (candidates.length === 0) {
        setModalSessionFlightsInfo({
          ...flightsInfo,
          bulkUpload: {
            notFoundFlights: currentNotFoundFlights,
            bulkUploadLoading: false,
          },
        });
      } else {
        const {
          eligibleFlights,
          flightsStatus,
          ineligibleFlights,
          attachedToAnotherStrategy,
        } = flightsInfo;

        const eligibility = strategyId
          ? await Eligibility.checkFlightsEligibility(_.toString(strategyTypeId), { flights: candidates })
          : await Eligibility.flightFirst({ flights: candidates });

        const eligible = _.filter(
          eligibility.data,
          (elig) => elig.eligible
            && !_.includes(attachedToCurrent, elig.externalId)
            && !isAlreadyAttached(elig, _.toString(strategyId)),
        );

        const newFlightsStatus = { ...flightsStatus };

        const ineligible = _.filter(eligibility.data, ['eligible', false]);

        const alreadyAttached = _.filter(
          eligibility.data,
          (elig) => elig.strategy && isAlreadyAttached(elig, _.toString(strategyId)),
        );

        const allFlights = _.concat(eligible, ineligible, alreadyAttached);

        _.forEach(allFlights, (flight) => {
          const reducedFlight = _.pick(flight, ['externalId', 'externalType', 'member', 'advertiser']);
          const key = flightKey(flight);
          const flightObj = { ...reducedFlight } as any;
          const flightMetadata = _.find(allFlights, (f) => flightKey(f) === key);
          if (flight.eligible) {
            if (flightMetadata.strategy === +strategyId) {
              flightObj.status = FLIGHT_ATTACH_STATUS.ATTACHED.style;
            } else if (_.isFinite(flightMetadata.strategy) && flightMetadata.strategy !== strategyId) {
              // if flight is attachedToAnotherStrategy, no need to give flightsStatus a status attribute.
              newFlightsStatus[key] = flightObj;
              return;
            } else {
              flightObj.status = FLIGHT_ATTACH_STATUS.ADD.style;
            }
          } else {
            flightObj.status = FLIGHT_ATTACH_STATUS.INELIGIBLE.style;
          }
          newFlightsStatus[key] = flightObj;
        });

        const extIdsToRemove = new Set(_.map(_.concat(alreadyAttached, eligible), (f) => f.externalId));
        const newFlightCandidates = _.filter(flightsInfo.flightCandidates, (f) => !extIdsToRemove.has(f.externalId));

        const newModalSessionFlightsInfo = {
          ...flightsInfo,
          eligibleFlights: mergeFlights(eligible, eligibleFlights),
          flightsStatus: newFlightsStatus,
          ineligibleFlights: mergeFlights(ineligible, ineligibleFlights),
          attachedToAnotherStrategy: mergeFlights(alreadyAttached, attachedToAnotherStrategy),
          flightCandidates: newFlightCandidates,
          bulkUpload: {
            notFoundFlights: currentNotFoundFlights,
            bulkUploadLoading: false,
            attachedToCurrentFlights: attachedToCurrent,
          },
        } as ModalSessionFlightsInfoType;
        setSessionAttachFlights(mergeFlights(sessionAttachFlights, eligible, alreadyAttached));
        setModalSessionFlightsInfo(newModalSessionFlightsInfo);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setModalSessionFlightsInfo({ ...flightsInfo, bulkUpload: { bulkUploadFailedMsg: 'Bulk upload failed', bulkUploadLoading: false } });
    }
  };
  return (
    <Form>
      <WppTextareaInput
        style={ATTACH_DSP_OBJECT_STYLES.bulkTextArea}
        placeholder={`Enter ${flightDisplayName.single} IDs`}
        message={`Please paste no more then ${MAX_UPLOAD_AT_ONCE} elements at a time. If you need to attach more please work in batches.`}
        onWppChange={(event: WppTextareaInputCustomEvent<TextareaInputChangeEventDetail>) => onTextChange(event.detail.value)}
      />
      <WppButton
        variant="secondary"
        disabled={disabled}
        loading={bulkUploadLoading}
        style={ATTACH_DSP_OBJECT_STYLES.bulkUploadBtn}
        onClick={(e) => {
          e.preventDefault();
          bulkUpload(modalSessionFlightsInfo);
        }}
        size="s"
      >
        Upload
      </WppButton>
      {
        bulkUploadFailedMsg ? <Message color="red">{bulkUploadFailedMsg}</Message>
          : (
            <>
              {
                !_.isEmpty(notFoundFlights)
                && (
                  <FlightUploadMessage
                    error
                    list={notFoundFlights}
                    msgHeader={getMsgHeader(notFoundFlights, flightDisplayName).notFoundFlights}
                  />
                )
              }
              {
                !_.isEmpty(bulkUploadedFlights)
                && (
                  <FlightUploadMessage
                    success
                    list={bulkUploadedFlights}
                    msgHeader={getMsgHeader(bulkUploadedFlights, flightDisplayName).bulkUploadedFlights}
                  />
                )
              }
              {
                !_.isEmpty(attachedToCurrentFlights)
                && (
                  <FlightUploadMessage
                    list={attachedToCurrentFlights}
                    msgHeader={getMsgHeader(attachedToCurrentFlights, flightDisplayName).attachedToCurrentFlights}
                  />
                )
              }
            </>
          )
      }
    </Form>
  );
};

export default BulkUploader;
