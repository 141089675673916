import _ from 'lodash';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { WppInlineMessage } from 'buildingBlocks';
import { WIZARD_STEPS, WizardSteps } from 'containers/StrategyWizard/constants';
import { OptimizationLevelType } from 'containers/StrategyWizard/utils';
import { COPILOT_LAYOUT } from 'globalStyles';
import OptimizationTypeSelection from './OptimizationTypeSelection';
import { OptimizationType, OPTIMIZATION_TYPE_OPTIONS } from '../constants';
import { configuringCrossPlatformStratCheck } from '../utils';

const { SPACING } = COPILOT_LAYOUT;

const getBannerDesc = (selectedOptType: OptimizationType, optLevel: OptimizationLevelType) => {
  switch (selectedOptType) {
    case (OptimizationType.campaign):
      return `Copilot will manage budget according to the budget intervals inherited from the ${optLevel.displayName.single} attached to this strategy. Targeting will not be updated on any attached objects.`;
    case (OptimizationType.lineItem):
      return `Copilot will manage the ${optLevel.displayName.multiple} according to the goals configured in this strategy. Targeting and budgets will not be updated on any attached ${optLevel.displayName.multiple}.`;
    default:
      return _.get(OPTIMIZATION_TYPE_OPTIONS, `[${selectedOptType}].bannerDesc`);
  }
};

type OptimizationTypeBannerProps = {
  isCreateMode: boolean
  canAccessCrossPlatform: boolean
  flightLimitReached: boolean
  optTypeSelectionOpen: boolean
  setOptTypeSelectionOpen: (x: any) => void
  selectedOptType: OptimizationType
  setSelectedOptType: (x: any) => void
  strategyId?: number
};

const OptimizationTypeBanner = (props: OptimizationTypeBannerProps) => {
  const { isCreateMode, canAccessCrossPlatform, flightLimitReached, optTypeSelectionOpen, setOptTypeSelectionOpen, selectedOptType, setSelectedOptType, strategyId } = props;
  const optimizationLevel = useWatch({ name: 'optimizationLevel' });
  const selectedOptTypeDetails = OPTIMIZATION_TYPE_OPTIONS[selectedOptType];
  const bannerDesc = (configuringCrossPlatformStratCheck(selectedOptType) || !optimizationLevel) ? selectedOptTypeDetails?.bannerDesc : getBannerDesc(selectedOptType, optimizationLevel);
  const navigate = useNavigate();

  const onChangeInlinemassage = () => {
    setOptTypeSelectionOpen(true);
    navigate(`/strategies/wizard/${WIZARD_STEPS[WizardSteps.attachFlightsStep].subSteps.optimizationType.id}`);
  };

  return (
    <>
      {!optTypeSelectionOpen && (
        <div style={{ ...(!selectedOptType && { display: 'none' }), ...(flightLimitReached && { marginBottom: SPACING[24] }) }}>
          <WppInlineMessage
            size="l"
            titleText={`${selectedOptType && selectedOptTypeDetails.bannerTitle}`}
            message={`${selectedOptType && bannerDesc}`}
            className={strategyId ? 'selected-Otype-edit' : 'select-Otype-inline'}
            type="information"
            showTooltipFrom={1000}
            hideCloseBtn
            actionBtnText="Change Optimization Type"
            onWppClickActionBtn={() => onChangeInlinemassage()}
          />
        </div>
      )}
      {optTypeSelectionOpen && (
        <OptimizationTypeSelection
          canAccessCrossPlatform={canAccessCrossPlatform}
          selectedOptType={selectedOptType}
          setSelectedOptType={setSelectedOptType}
          disableOptTypeModal={!isCreateMode}
        />
      )}
    </>
  );
};

export default OptimizationTypeBanner;
