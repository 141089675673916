import _ from 'lodash';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { REVENUE_TYPE_OPTIONS } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { hasMissingValue, hasSingleOutcomeAndValue } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { useGoalSectionContext } from 'containers/StrategyWizard/steps/GoalSelection/contexts/GoalSectionProvider';
import { checkPermissions, Permission } from 'utils/featureFlags';
import { RevenueType } from 'constantsBase';
import { GlobalState } from 'reducers';
import { User } from 'utils/types';
import { RevenueTypeOutcomeOptions } from 'containers/StrategyWizard/constants';
import MultipleRevenueOutcomes from './MultipleRevenueOutcomes';

const RevenueTypeSection = () => {
  const { setValue } = useFormContext();
  const user = useSelector<GlobalState>((rootState) => rootState.login.user) as User;
  const {
    wizardFormValues: {
      attachFlightsStep: { attachedFlights },
    },
  } = useGoalSectionContext();
  const budgetConfig = useWatch({ name: 'budget' });

  // temporary check for CPM revenue type permission - will eventually be removed
  const revenueTypeOptions = checkPermissions(user, Permission.cpmRevenueType)
    ? REVENUE_TYPE_OPTIONS
    : _.filter(REVENUE_TYPE_OPTIONS, (revType) => !_.isEqual(revType.value, RevenueType.cpm));

  useEffect(() => {
    if (budgetConfig && !hasMissingValue(budgetConfig)) {
      setValue('revenueOutcomeType', hasSingleOutcomeAndValue(budgetConfig) ? RevenueTypeOutcomeOptions.single : RevenueTypeOutcomeOptions.multiple);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetConfig]);

  return (
    <MultipleRevenueOutcomes
      revenueTypeOptions={revenueTypeOptions}
      attachedFlights={attachedFlights}
    />
  );
};

export default RevenueTypeSection;
