import _ from 'lodash';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { ObjectMultiDropdown } from 'buildingBlocks';
import { getFlightOptionsWithDspIcon } from 'components/OptionWithIcon/OptionWithIcon';
import { DSP } from 'constantsBase';
import { formatFlightDisplayName, formatCrossPlatformFlightDisplayName } from 'containers/StrategyWizard/constants';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { getFlightCandidates, getBrandFlightCandidates, checkEligibility } from 'containers/StrategyWizard/steps/AttachFlights/actions';
import { MAX_FLIGHTS_ALLOWED, OptimizationType } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { configuringCampaignStratCheck, configuringCrossPlatformStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { CONVERSION_BASED_GOALS } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FlightCandidate, ModalSessionFlightsInfoType } from 'containers/StrategyWizard/types';
import { FlightDisplayName, hasMultipleExternalTypesAttached } from 'containers/StrategyWizard/utils';
import { Flight } from 'utils/types';
import { getFilter, mergeFlights } from './utils';

type Props = {
  selectedOptType: OptimizationType
  externalTypeIds: Array<number>
  flightDisplayName: FlightDisplayName
  strategyId?: string | number
  strategyTypeId?: string | number
  modalSessionFlightsInfo: ModalSessionFlightsInfoType
  setModalSessionFlightsInfo: (x: any) => void
  sessionAttachFlights: Array<Flight>
  setSessionAttachFlights: (x: Array<Flight>) => void
  defaultCurrencyId: number | null
  setDefaultCurrencyId: (x: any) => void
  validMembers: Array<number> | '*'
};

const FlightCandidateFormField = (props: Props) => {
  const {
    selectedOptType,
    externalTypeIds,
    flightDisplayName: { single, multiple },
    strategyId,
    strategyTypeId,
    modalSessionFlightsInfo,
    setModalSessionFlightsInfo,
    sessionAttachFlights,
    setSessionAttachFlights,
    defaultCurrencyId,
    setDefaultCurrencyId,
    validMembers,
  } = props;

  const { wizardFormValues } = useStrategyWizardContext();
  const goalType = _.get(wizardFormValues, 'goalSelectionStep.goal.type');
  const attachedFlights = useWatch({ name: 'attachedFlights' });
  const advertiser = useWatch({ name: 'advertiser' });
  const member = useWatch({ name: 'member' });
  const brand = useWatch({ name: 'brand' });
  const {
    flightCandidates,
    loadingFlightCandidates,
    searchTerm,
  } = modalSessionFlightsInfo;

  const FLIGHT_CANDIDATE_SELECTION_LIMIT = 1;
  const configuringCrossPlatformStrat = configuringCrossPlatformStratCheck(selectedOptType);
  const configuringCampaignStrat = configuringCampaignStratCheck(selectedOptType);
  const selectionLimit = configuringCampaignStrat ? FLIGHT_CANDIDATE_SELECTION_LIMIT : MAX_FLIGHTS_ALLOWED;
  const allFlightsSelected = _.concat(attachedFlights, sessionAttachFlights);
  const flightLimitReached = _.size(allFlightsSelected) === selectionLimit;
  const hasMultipleFlightExternalTypes = hasMultipleExternalTypesAttached(allFlightsSelected);
  const formatedDisplayName = hasMultipleFlightExternalTypes ? formatCrossPlatformFlightDisplayName(allFlightsSelected) : formatFlightDisplayName(allFlightsSelected);
  const disabled = loadingFlightCandidates || flightLimitReached || (!advertiser && !brand);

  const fetchNewFlightCandidate = async (flightsSelected: Array<Flight>, query = searchTerm): Promise<Array<Flight>> => {
    const newCandidates = (brand && configuringCrossPlatformStrat) ? (
      await getBrandFlightCandidates(
        flightsSelected,
        brand.id,
        _.get(_.last(flightsSelected), 'currency'),
        { ...modalSessionFlightsInfo, searchTerm: query },
        setModalSessionFlightsInfo,
        validMembers,
        _.get(member, 'id'),
        _.get(advertiser, 'id'),
        query,
      )
    ) : (
      await getFlightCandidates(
        flightsSelected,
        getFilter(advertiser.id, member.id, strategyTypeId, query),
        { ...modalSessionFlightsInfo, searchTerm: query },
        setModalSessionFlightsInfo,
        strategyId,
        externalTypeIds,
        configuringCrossPlatformStrat,
      )
    );
    return newCandidates;
  };

  const handleOnChange = async (flightsSelected) => {
    // takes place asynchronously, ensures the next onChange uses the correct array of flights
    setSessionAttachFlights(mergeFlights(sessionAttachFlights, flightsSelected));

    // set default currency upon first flight selection if no flights are currently attached
    const selectedFlightCurrency = _.get(_.last(flightsSelected), 'currency');
    if (!_.isEqual(defaultCurrencyId, selectedFlightCurrency)) {
      setDefaultCurrencyId(selectedFlightCurrency);
    }

    const newCandidates = await fetchNewFlightCandidate(mergeFlights(flightsSelected, attachedFlights, sessionAttachFlights), '');
    checkEligibility(
      flightsSelected,
      { ...modalSessionFlightsInfo, flightCandidates: newCandidates, searchTerm: '' },
      setModalSessionFlightsInfo,
      strategyTypeId,
      strategyId,
    );
  };
  // remove Amazon flight candidates when configuring a cross-platform strat with a pixel supported goal type
  const filteredFlightCandidates = (configuringCrossPlatformStrat && _.includes(CONVERSION_BASED_GOALS, goalType))
    ? _.filter(flightCandidates, (flight) => !_.isEqual(flight.dsp, DSP.AMZN.id))
    : flightCandidates;

  return (
    <ObjectMultiDropdown
      fluid
      selection
      scrolling
      disableRemoveItem
      closeOnChange
      name="attachedFlights"
      input={{
        value: sessionAttachFlights,
        name: 'attachedFlights',
      }}
      text={(attachedFlights.length > 0 || sessionAttachFlights.length > 0)
        ? `${formatedDisplayName} selected${flightLimitReached ? ' (Limit Reached)' : ''}`
        : undefined}
      placeholder={configuringCampaignStrat ? `Select ${single}...` : `Select ${multiple}...`}
      keyFn={(fc: FlightCandidate) => `[${fc.externalId}] - ${fc.name}`}
      options={getFlightOptionsWithDspIcon(filteredFlightCandidates)}
      search={{
        searchType: 'api',
        onSearchChange: (_event, value) => {
          fetchNewFlightCandidate(allFlightsSelected, value.searchQuery);
        },
        debounce: { timer: 250 },
      }}
      onChange={(flightsSelected) => {
        handleOnChange(flightsSelected);
      }}
      renderLabel={() => undefined}
      loading={loadingFlightCandidates}
      disabled={disabled}
      selectionLimit={selectionLimit}
      customstyle={{ wordBreak: 'break-all' }}
    />
  );
};

export default FlightCandidateFormField;
