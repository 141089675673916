import _ from 'lodash';
import React, { CSSProperties, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon, Loader, Popup, WppIconAdd, WppIconTrash, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTypography } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { flightKey, getDspFromExtTypeId, isBulkCreateMode } from 'containers/StrategyWizard/utils';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { useBulkCreateStrategyWizardContext } from 'containers/BulkCreateStrategyWizard/contexts/BulkCreateStrategyWizardProvider';
import { FlightCategory } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { FLIGHT_DETAIL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { Flight } from 'utils/types';
import { displayDate } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import ActivationFlightModal from './ActivationFlightModal';

const {
  baseTable,
  additionalExpiredStyles,
  additionalToBeDetachedStyles,
  reactivatedFlightStyles,
  loaderOrAccordion,
  firstHeaderCell,
  flightName,
  dspIcon,
  objectType,
  flightDates,
  endIcon,
  iconStyle,
  popUp,
} = FLIGHT_DETAIL_STYLES;

type AttachFlightsDetailsProps = {
  flight: Flight
  isHigherOrderOptLvl: boolean
  removeFlight: Function
  flightCategory?: string
  index?: number
  activeIndex?: number
  flightLimitReached?: boolean
  eligibile?: boolean
  editingCrossPlatformStrat?: boolean
};

const AttachFlightsDetails = (props: AttachFlightsDetailsProps) => {
  const {
    flight,
    isHigherOrderOptLvl,
    flightCategory,
    removeFlight,
    activeIndex,
    eligibile,
    index,
    flightLimitReached,
    editingCrossPlatformStrat,
  } = props;

  const { name, startDate, endDate, timezone, externalType } = flight;
  const location = useLocation();
  const [activationModalOpen, setActivationModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = isBulkCreateMode(location?.pathname) ? useBulkCreateStrategyWizardContext() : useStrategyWizardContext();
  const { wizardFormValues } = context;
  const key = flightKey(flight);
  const isAttachedToThisStrategy = _.isEqual(flightCategory, FlightCategory.attachedToThisStrategy);
  const flightToBeDetached = _.isEqual(flightCategory, FlightCategory.toBeDetached);
  const crossPlatformFlightToBeDeactivated = _.isEqual(flightCategory, FlightCategory.toBeDeactivated);
  const ineligToBeDeactivatedCrossPlatFlight = crossPlatformFlightToBeDeactivated && !eligibile;
  const reactivatedFlight = _.isEqual(flightCategory, FlightCategory.reactivatedFlights);
  const deactivedCrossPlatformFlight = _.isEqual(flightCategory, FlightCategory.deactivatedFlights);
  const ineligibleFlight = flightCategory === FlightCategory.ineligibleFlights;
  const eligCPFlightWithIncompatibleBudgetType = _.isEqual(flightCategory, FlightCategory.eligCPFlightsWithoutAmountBudgetType);
  const eligCPFlightWithSpend = _.isEqual(flightCategory, FlightCategory.eligCPFlightsWithSpend);
  const isRemovableFlight = _.isEqual(flightCategory, FlightCategory.eligibleFlights)
    || eligCPFlightWithIncompatibleBudgetType
    || eligCPFlightWithSpend
    || (editingCrossPlatformStrat && reactivatedFlight)
    || (!editingCrossPlatformStrat && (ineligibleFlight || isAttachedToThisStrategy));
  const isReattachableFlight = flightToBeDetached || crossPlatformFlightToBeDeactivated;
  const showAccordion = isHigherOrderOptLvl && (flightCategory === FlightCategory.eligibleFlights || isAttachedToThisStrategy);
  const accordionOpen = activeIndex === index;
  const tableStyles = { ...baseTable, ...(accordionOpen && { borderBottom: 'none' }) };
  const styleForIneligibleDetachedOrDeactivated = ((eligibile && !deactivedCrossPlatformFlight) || isReattachableFlight)
    ? { ...tableStyles, ...additionalToBeDetachedStyles }
    : { ...tableStyles, ...additionalExpiredStyles };
  const deactivatedFlightConditionalStyling = deactivedCrossPlatformFlight && { opacity: '60%' };
  const ineligibleDetachedOrDeactivated = ineligibleFlight || deactivedCrossPlatformFlight || isReattachableFlight || eligCPFlightWithIncompatibleBudgetType || eligCPFlightWithSpend;
  const style = ineligibleDetachedOrDeactivated ? styleForIneligibleDetachedOrDeactivated : { ...tableStyles, ...(reactivatedFlight && reactivatedFlightStyles) };
  const getCellStyles = (cellStyle: CSSProperties): CSSProperties => ({ ...cellStyle, ...deactivatedFlightConditionalStyling });
  const flightDatesToDisplay = `${displayDate(startDate, externalType, timezone)} - ${displayDate(endDate, externalType, timezone)}`;
  const externalTypeDisplayName = _.get(FLIGHT_EXTERNAL_TYPE.getById(externalType), 'wizardDisplayName') ?? FLIGHT_EXTERNAL_TYPE.getById(externalType).displayName;
  const budgetAllocationState = _.get(wizardFormValues, 'budgetAllocationState');
  const { kind } = budgetAllocationState;

  const handleRemoveFlight = () => {
    switch (flightCategory) {
      case (FlightCategory.ineligibleFlights):
      case (FlightCategory.attachedToThisStrategy):
        removeFlight(key, flightCategory, FlightCategory.toBeDetached);
        break;
      case (FlightCategory.reactivatedFlights):
        removeFlight(key, flightCategory, FlightCategory.deactivatedFlights);
        break;
      default:
        removeFlight(key, flightCategory);
    }
  };

  return (
    <WppTable style={{ ...style, border: 'none', borderBottomStyle: showAccordion ? 'hidden' : 'none' }}>
      <WppTableBody>
        <WppTableBodyRow>
          {_.isEqual(kind, Status.loading) && (
            <WppTableBodyCell style={(ineligibleDetachedOrDeactivated || !isHigherOrderOptLvl || !_.isEqual(kind, Status.hasData)) ? firstHeaderCell : loaderOrAccordion}>
              {(showAccordion && _.isEqual(kind, Status.loading))
                && (
                  <Loader active size="mini" inline />
                )}
            </WppTableBodyCell>
          )}
          <WppTableBodyCell style={getCellStyles(flightName)}>
            <WppTypography type="s-body" title={name}>{name}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell style={getCellStyles(dspIcon)}>
            <DspIcon dspId={getDspFromExtTypeId(externalType)} />
          </WppTableBodyCell>
          <WppTableBodyCell style={getCellStyles(objectType)}>
            <WppTypography type="s-body">{externalTypeDisplayName}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell style={getCellStyles(flightDates)}>
            <WppTypography type="s-body">{flightDatesToDisplay}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell style={endIcon}>
            {isRemovableFlight && (<WppIconTrash onClick={handleRemoveFlight} style={iconStyle} color="var(--wpp-grey-color-800)" />)}
            {(editingCrossPlatformStrat && (isAttachedToThisStrategy || ineligibleFlight)) && (
              <ActivationFlightModal
                activationModalOpen={activationModalOpen}
                setActivationModalOpen={setActivationModalOpen}
                flight={flight}
                removeFlight={() => {
                  removeFlight(key, flightCategory, FlightCategory.toBeDeactivated);
                }}
                isDeactivation
              />
            )}
            {isReattachableFlight && (((eligibile && !flightLimitReached) || ineligToBeDeactivatedCrossPlatFlight)
              ? (
                <WppIconAdd
                  onClick={() => {
                    removeFlight(
                      key,
                      flightCategory,
                      (ineligToBeDeactivatedCrossPlatFlight ? FlightCategory.ineligibleFlights : FlightCategory.attachedToThisStrategy),
                    );
                  }}
                  style={iconStyle}
                />
              ) : (
                <Popup
                  style={popUp}
                  position="top right"
                  content={flightLimitReached ? 'Object attachment limit reached.' : 'This flight is ineligible.'}
                  trigger={(
                    <Icon
                      name="plus"
                      disabled
                    />
                  )}
                />
              )
            )}
            {(editingCrossPlatformStrat && deactivedCrossPlatformFlight) && (
              <ActivationFlightModal
                activationModalOpen={activationModalOpen}
                setActivationModalOpen={setActivationModalOpen}
                flight={flight}
                removeFlight={() => {
                  removeFlight(key, flightCategory, FlightCategory.reactivatedFlights);
                }}
              />
            )}
          </WppTableBodyCell>
        </WppTableBodyRow>
      </WppTableBody>
    </WppTable>
  );
};

export default AttachFlightsDetails;
