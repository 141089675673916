import _ from 'lodash';
import React, { MouseEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { WppActionButton } from 'buildingBlocks';
import { Status } from 'containers/StrategyWizard/types';
import { FlightDisplayName } from 'containers/StrategyWizard/utils';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { FetchState } from 'utils/types';

type SyncButtonProps = {
  displayName: string
  loading?: boolean
  onClick?: MouseEventHandler
};

const SyncButton = ({
  loading,
  onClick,
  displayName,
}: SyncButtonProps) => {
  const { getValues } = useFormContext();
  const { advertiser } = getValues();

  return (
    <WppActionButton
      onClick={onClick}
      disabled={_.isNil(advertiser)}
      style={MODAL_STYLES.advertiseRefreshButton}
    >
      {loading ? 'Loading...' : `Refresh ${displayName}`}
    </WppActionButton>
  );
};

type Props = {
  onClick: MouseEventHandler
  status: Status
  flightDisplayName: FlightDisplayName
};

const FlightSyncComponent = ({
  onClick,
  status,
  flightDisplayName: { multiple },
}: Props) => {
  switch (status.kind) {
    case FetchState.loading:
      return <SyncButton displayName={multiple} loading />;
    case FetchState.initial:
    case FetchState.success:
    case FetchState.error:
    default:
      return <SyncButton onClick={onClick} displayName={multiple} />;
  }
};

export default FlightSyncComponent;
